import { ref } from "vue";
import { apiAxios } from "@/lib/axios";
import useRoleApplication from "@/composables/useRoleApplication";

const isManagingCv = ref(false);
const roleApplicationCv = ref(null);
const parsedCv = ref(null);
const individualCvs = ref([]);

export default function useCvs() {
  const getIndividualCvs = async () => {
    try {
      individualCvs.value = await fetchCvsAndSort();
    } catch {
      individualCvs.value = [];
    }
  };

  const fetchCvsAndSort = async () => {
    const { data: cvs } = await apiAxios.get(`/individual/cvs`);

    const sortedCvsNewestFirst = cvs.sort(function (a, b) {
      return new Date(b.created_at) - new Date(a.created_at);
    });

    return sortedCvsNewestFirst;
  };

  const getRoleApplicationCv = async () => {
    const { roleApplication } = useRoleApplication();

    const cvIdFromApplication = roleApplication.value.cv_id;

    if (cvIdFromApplication) {
      roleApplicationCv.value = individualCvs.value.find(
        cv => cv.id === cvIdFromApplication
      );
    } else if (individualCvs.value.length > 0) {
      const unarchivedCvs = individualCvs.value.filter(cv => !cv.archived);

      const [mostRecentlyUploadedCv] = unarchivedCvs.sort(function (a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });

      await updateRoleApplicationCv(mostRecentlyUploadedCv);
    }
  };

  const updateRoleApplicationCv = async updatedCv => {
    roleApplicationCv.value = updatedCv;

    const { updateRoleApplication } = useRoleApplication();
    await updateRoleApplication({
      cv_id: updatedCv?.id || null,
      cv_filename: updatedCv?.filename || null,
    });
  };

  const deleteCv = async id => {
    await apiAxios.delete(`${process.env.VITE_BASE_URL}/individual/cvs/${id}`);
    await getIndividualCvs();

    const isCvSelected = id === roleApplicationCv.value?.id;
    if (isCvSelected) {
      await updateRoleApplicationCv(null);
    }
  };

  const showManageCvModal = () => {
    isManagingCv.value = true;
  };

  const hideManageCvModal = () => {
    isManagingCv.value = false;
  };

  return {
    getIndividualCvs,
    getRoleApplicationCv,
    updateRoleApplicationCv,
    deleteCv,
    showManageCvModal,
    hideManageCvModal,
    isManagingCv,
    individualCvs,
    roleApplicationCv,
    parsedCv,
  };
}
