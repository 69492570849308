<template>
  <div class="bg-sage-50 relative">
    <!-- Tabs hash routing target -->
    <span id="tabs" class="absolute top-44"></span>
    <!-- Back link -->
    <div
      v-if="isMember"
      class="sticky top-14 md:top-20 bg-sage-50 w-full z-10 transition-shadow"
      :class="showBackLinkShadow ? 'shadow-ivory' : ''"
    >
      <div
        class="max-w-screen-xl md:mx-auto flex items-center gap-4 py-2 px-4 md:px-10 lg:py-4 lg:px-28 xl:px-0"
      >
        <router-link
          :to="backLink"
          class="flex items-center justify-center text-cta-tertiary p-2 gap-4 pl-0 xl:pl-2"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            class="fill-current"
          >
            <path
              d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
            />
          </svg>
          Back
        </router-link>
      </div>
    </div>
    <div
      class="w-full items-center max-w-screen-xl md:mx-auto lg:mb-20"
      :class="{ 'mt-10': !isMember }"
    >
      <does-not-exist v-if="!role" :backLink="isMember ? backLink : null" />
      <expired-role
        v-if="displayExpiredRoleWindow"
        :backLink="isMember ? backLink : null"
      />
      <div
        v-else-if="role"
        class="bg-white py-6 px-4 md:p-10 lg:py-10 lg:px-28 xl:py-14 xl:px-48"
      >
        <!-- Key role details -->
        <div class="flex flex-col gap-6 lg:gap-8">
          <div class="flex items-start gap-2">
            <div class="flex-grow flex flex-col gap-2">
              <h2 class="text-title-h2 m-0" data-cy="role-title">
                {{ role.title }}
              </h2>
              <h3 class="copy-emphasis m-0" data-cy="role-organisation-name">
                {{ role.name }}
              </h3>
              <role-type-badge
                :variant="role.sector === 'nfp' ? 'probono' : 'compensated'"
              />
            </div>
            <div
              class="bg-contain bg-center bg-no-repeat h-12 w-20 md:h-20 md:w-32"
              :style="`background-image: url('${role.logo_thumbnail_public}')`"
            ></div>
          </div>
          <div class="gap-3 flex flex-col">
            <p class="text-divider text-grey-600 m-0">About this role</p>
            <ul
              class="list-none p-0 gap-3 flex flex-col m-0 md:flex-row md:flex-wrap md:gap-4"
            >
              <li
                v-if="role?.compensation_details"
                class="flex items-center gap-2 text-nudging text-grey-900"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                  class="fill-current"
                >
                  <path
                    d="M13.6667 4.85333V3.33333C13.6667 2.6 13.0667 2 12.3334 2H3.00008C2.26008 2 1.66675 2.6 1.66675 3.33333V12.6667C1.66675 13.4 2.26008 14 3.00008 14H12.3334C13.0667 14 13.6667 13.4 13.6667 12.6667V11.1467C14.0601 10.9133 14.3334 10.4933 14.3334 10V6C14.3334 5.50667 14.0601 5.08667 13.6667 4.85333ZM13.0001 6V10H8.33341V6H13.0001ZM3.00008 12.6667V3.33333H12.3334V4.66667H8.33341C7.60008 4.66667 7.00008 5.26667 7.00008 6V10C7.00008 10.7333 7.60008 11.3333 8.33341 11.3333H12.3334V12.6667H3.00008Z"
                  />
                  <path
                    d="M10.3334 9C10.8857 9 11.3334 8.55228 11.3334 8C11.3334 7.44772 10.8857 7 10.3334 7C9.78113 7 9.33341 7.44772 9.33341 8C9.33341 8.55228 9.78113 9 10.3334 9Z"
                  />
                </svg>
                {{ role.compensation_details }}
              </li>
              <li
                v-if="role.commitment"
                class="flex items-center gap-2 text-nudging text-grey-900"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                  class="fill-current shrink-0"
                >
                  <path
                    d="M13.3333 1.99999H12.6666V0.666656H11.3333V1.99999H4.66659V0.666656H3.33325V1.99999H2.66659C1.93325 1.99999 1.33325 2.59999 1.33325 3.33332V14C1.33325 14.7333 1.93325 15.3333 2.66659 15.3333H13.3333C14.0666 15.3333 14.6666 14.7333 14.6666 14V3.33332C14.6666 2.59999 14.0666 1.99999 13.3333 1.99999ZM13.3333 14H2.66659V6.66666H13.3333V14ZM13.3333 5.33332H2.66659V3.33332H13.3333V5.33332Z"
                  />
                </svg>

                {{ role.commitment }}
              </li>
              <li
                v-if="role.locations_formatted"
                class="flex items-center gap-2 text-nudging text-grey-900"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                  class="fill-current shrink-0"
                >
                  <path
                    d="M7.99992 2.66668C9.28659 2.66668 11.3333 3.60001 11.3333 6.10001C11.3333 7.54001 10.1866 9.21334 7.99992 10.98C5.81325 9.21334 4.66659 7.53334 4.66659 6.10001C4.66659 3.60001 6.71325 2.66668 7.99992 2.66668ZM7.99992 1.33334C5.81992 1.33334 3.33325 2.97334 3.33325 6.10001C3.33325 8.18001 4.88659 10.3733 7.99992 12.6667C11.1133 10.3733 12.6666 8.18001 12.6666 6.10001C12.6666 2.97334 10.1799 1.33334 7.99992 1.33334Z"
                  />
                  <path
                    d="M7.99992 4.66668C7.26659 4.66668 6.66659 5.26668 6.66659 6.00001C6.66659 6.73334 7.26659 7.33334 7.99992 7.33334C8.35354 7.33334 8.69268 7.19287 8.94273 6.94282C9.19278 6.69277 9.33325 6.35363 9.33325 6.00001C9.33325 5.64639 9.19278 5.30725 8.94273 5.0572C8.69268 4.80715 8.35354 4.66668 7.99992 4.66668ZM3.33325 13.3333H12.6666V14.6667H3.33325V13.3333Z"
                  />
                </svg>
                {{ role.locations_formatted }}
              </li>
              <li
                v-if="role.deadline"
                class="flex items-center gap-2 text-nudging text-grey-900"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                  class="fill-current shrink-0"
                >
                  <path
                    d="M10 1H6V2.33333H10V1ZM7.33333 9.66667H8.66667V5.66667H7.33333V9.66667ZM12.6867 5.26L13.6333 4.31333C13.3467 3.97333 13.0333 3.65333 12.6933 3.37333L11.7467 4.32C10.7133 3.49333 9.41333 3 8 3C4.68667 3 2 5.68667 2 9C2 12.3133 4.68 15 8 15C11.32 15 14 12.3133 14 9C14 7.58667 13.5067 6.28667 12.6867 5.26ZM8 13.6667C5.42 13.6667 3.33333 11.58 3.33333 9C3.33333 6.42 5.42 4.33333 8 4.33333C10.58 4.33333 12.6667 6.42 12.6667 9C12.6667 11.58 10.58 13.6667 8 13.6667Z"
                  />
                </svg>
                Midnight on {{ formatDeadline(role.deadline) }}
              </li>
            </ul>
          </div>
          <quick-apply ref="quickApplyModal" :role="role" />

          <div
            class="flex flex-col justify-center gap-4 md:flex-row md:justify-start"
            v-if="hasApplied && !hideInteractionElements"
          >
            <a
              :href="`/applications/${role.id}`"
              class="button-secondary text-cta-secondary whitespace-nowrap lg:block sm:mr-2"
            >
              View application
            </a>
            <role-share-button :role="role" />
          </div>

          <div
            class="flex flex-col justify-center gap-4 md:flex-row md:justify-start"
            v-if="!hideInteractionElements"
          >
            <button
              v-if="showQuickApplyButton"
              @click="openQuickApplyModal"
              class="button-primary text-cta-primary flex justify-center items-center gap-2"
              data-cy="role-apply-button"
            >
              Quick Apply
            </button>
            <button
              v-else
              @click.prevent="applyToRole"
              class="button-primary text-cta-primary flex justify-center items-center"
              data-cy="role-apply-button"
            >
              Apply for role
            </button>

            <watchlist-button v-if="isMember" :roleId="role.id" />
            <role-share-button :role="role" />
          </div>
        </div>
        <!-- Tabs -->
        <div
          class="flex my-6 border-grey-400 border-solid border-0 border-b lg:mt-10"
          v-if="!hasApplied && isMember"
        >
          <router-link
            :to="`/roles/${role.id}`"
            class="tab text-cta-tertiary focus-ring-inset"
            data-cy="role-details-tab"
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              xmlns="http://www.w3.org/2000/svg"
              class="fill-current"
            >
              <path
                d="M7.33333 5.16667H11.3333V6.5H7.33333V5.16667ZM7.33333 7.83333H11.3333V9.16667H7.33333V7.83333ZM7.33333 10.5H11.3333V11.8333H7.33333V10.5ZM4.66667 5.16667H6V6.5H4.66667V5.16667ZM4.66667 7.83333H6V9.16667H4.66667V7.83333ZM4.66667 10.5H6V11.8333H4.66667V10.5ZM13.4 2.5H2.6C2.26667 2.5 2 2.76667 2 3.1V13.9C2 14.1667 2.26667 14.5 2.6 14.5H13.4C13.6667 14.5 14 14.1667 14 13.9V3.1C14 2.76667 13.6667 2.5 13.4 2.5ZM12.6667 13.1667H3.33333V3.83333H12.6667V13.1667Z"
              />
            </svg>
            Role <span class="hidden md:inline-block">details</span>
          </router-link>
          <router-link
            :to="`/roles/${role.id}/recommend`"
            class="tab text-cta-tertiary focus-ring-inset"
            data-cy="recommend-someone-tab"
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              xmlns="http://www.w3.org/2000/svg"
              class="fill-current"
            >
              <path
                d="M8.33992 7.15998C8.33992 6.05998 7.43992 5.15998 6.33992 5.15998C5.23992 5.15998 4.33992 6.05998 4.33992 7.15998C4.33992 8.25998 5.23992 9.15998 6.33992 9.15998C7.43992 9.15998 8.33992 8.25998 8.33992 7.15998ZM6.33992 7.82665C5.97325 7.82665 5.67325 7.52665 5.67325 7.15998C5.67325 6.79331 5.97325 6.49331 6.33992 6.49331C6.70659 6.49331 7.00659 6.79331 7.00659 7.15998C7.00659 7.52665 6.70659 7.82665 6.33992 7.82665ZM10.6733 9.15998C11.4133 9.15998 12.0066 8.56665 12.0066 7.82665C12.0066 7.08665 11.4133 6.49331 10.6733 6.49331C9.93325 6.49331 9.33325 7.08665 9.33992 7.82665C9.33992 8.56665 9.93325 9.15998 10.6733 9.15998ZM7.99992 1.83331C4.31992 1.83331 1.33325 4.81998 1.33325 8.49998C1.33325 12.18 4.31992 15.1666 7.99992 15.1666C11.6799 15.1666 14.6666 12.18 14.6666 8.49998C14.6666 4.81998 11.6799 1.83331 7.99992 1.83331ZM3.89992 11.9066C4.35325 11.5466 5.41325 11.1666 6.33992 11.1666C6.38659 11.1666 6.43992 11.1733 6.49325 11.1733C6.65325 10.7466 6.93992 10.3133 7.35992 9.93331C6.98659 9.86665 6.63325 9.82665 6.33992 9.82665C5.47325 9.82665 4.07992 10.1266 3.18659 10.78C2.85325 10.0866 2.66659 9.31331 2.66659 8.49331C2.66659 5.55331 5.05992 3.15998 7.99992 3.15998C10.9399 3.15998 13.3333 5.55331 13.3333 8.49331C13.3333 9.29331 13.1533 10.0533 12.8333 10.74C12.1666 10.3466 11.2599 10.16 10.6733 10.16C9.65992 10.16 7.67325 10.7 7.67325 11.96V13.8133C6.15992 13.7266 4.81325 13.0066 3.89992 11.9066Z"
              />
            </svg>
            Recommend <span class="hidden md:inline-block">someone</span>
          </router-link>
        </div>
        <template v-else>
          <hr class="section-separator-sm" />
        </template>
        <!-- Tab content -->
        <router-view :role="role" :backLink="backLink" />
      </div>
    </div>
  </div>
  <magic-link-modal
    v-if="showMagicLinkModal"
    :redirect-link="magicLinkRedirect"
    @close-magic-link-modal="showMagicLinkModal = false"
  />
</template>

<script lang="ts">
import { useRoute } from "vue-router";
import { formatDeadline } from "@/helpers/roleHelpers";
import useRole from "@/composables/useRole";
import { Role } from "@/models/Role";
import router from "@/lib/theVueRouter";
import WatchlistButton from "@/components/WatchlistButton.vue";
import DoesNotExist from "@/components/role/DoesNotExist.vue";
import ExpiredRole from "@/components/role/ExpiredRole.vue";
import useRolePageBackLink from "@/composables/useRolePageBackLink";
import QuickApply from "@/components/QuickApply.vue";
import useAuth from "@/composables/useAuth";
import useIndividual from "@/composables/useIndividual";
import MagicLinkModal from "@/components/MagicLinkModal.vue";
import RoleShareButton from "@/components/roles/RoleShareButton.vue";
import RoleTypeBadge from "@/components-v2/RoleTypeBadge.vue";

export default {
  components: {
    WatchlistButton,
    DoesNotExist,
    ExpiredRole,
    QuickApply,
    MagicLinkModal,
    RoleShareButton,
    RoleTypeBadge,
  },
  setup() {
    const { backLink, userEnteredRolePage } = useRolePageBackLink();
    const { authLevel } = useAuth();
    const { individual, isMember } = useIndividual();

    return {
      formatDeadline,
      backLink,
      userEnteredRolePage,
      individual,
      authLevel,
      isMember,
    };
  },
  data(): {
    role?: Role;
    scrollPosition?: number;
    showMagicLinkModal?: boolean;
    magicLinkRedirect?: string;
  } {
    return {
      role: undefined,
      scrollPosition: undefined,
      showMagicLinkModal: false,
      magicLinkRedirect: `https://${window.location.host}${this.$router.currentRoute.value.fullPath}/apply`,
    };
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  async mounted() {
    const route = useRoute();
    const { getRoleById } = useRole();
    const roleId = route.params.roleId as string;
    this.role = await getRoleById(roleId);

    if (this.$route.query.quick_apply_modal === "open") {
      this.$nextTick(() => {
        this.$refs.quickApplyModal.modalOpen = true;
      });
    }
  },
  created() {
    this.userEnteredRolePage(router?.options.history.state.back);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    hasApplied() {
      return this.role?.current_user_has_applied;
    },
    roleExpired() {
      return new Date(this.role.deadline) < new Date();
    },
    showBackLinkShadow() {
      return this.scrollPosition > 0;
    },
    quickApplyEnabled() {
      return this.role.is_quick_apply;
    },
    isPublicRole() {
      return this.$route.meta.isPublicRole;
    },
    showQuickApplyButton() {
      return this.quickApplyEnabled && this.isMember;
    },
    isPreviewMode() {
      return this.authLevel === "preview-mode";
    },
    hideInteractionElements() {
      return (
        this.authLevel === "preview-mode" &&
        this.$route.meta.hideInteractionElements
      );
    },
    displayExpiredRoleWindow() {
      return (
        this.role && this.roleExpired && !this.hasApplied && !this.isPreviewMode
      );
    },
  },
  methods: {
    handleScroll() {
      this.scrollPosition =
        document.documentElement.scrollTop || document.body.scrollTop;
    },
    openQuickApplyModal() {
      if (this.authLevel === "punchthrough") {
        window.location.replace(
          process.env.VITE_ACCOUNT_DOMAIN +
            "/login?redirect=" +
            window.location +
            "?quick_apply_modal=open"
        );
      } else {
        this.$refs.quickApplyModal.modalOpen = true;
      }
    },
    applyToRole() {
      if (
        this.authLevel == "standard" ||
        this.authLevel == "punchthrough" ||
        this.authLevel == "impersonate"
      ) {
        this.$router.push(`/roles/${this.role.id}/apply`);
      } else {
        this.showMagicLinkModal = true;
      }
    },
  },
};
</script>

<style scoped>
.tab {
  @apply flex py-3 px-5 items-center justify-center gap-2 text-sage-700 border-grey-400 border-solid border-0 rounded-t-lg flex-grow flex-1 md:grow-0;
  background-color: white;
  margin-bottom: -1px;
}
.tab:not(.router-link-exact-active) {
  @apply border-b !important;
}
.tab.router-link-exact-active {
  @apply border border-b-0 !important;
}
</style>
